import GATSBY_COMPILED_MDX from "/home/minimaniac/Projects/WebDev/website/content/about.mdx";
import React, {useMemo} from 'react';
import {About, Hero, OtherProjects, Projects} from '@components/sections/Home';
import {Page} from '@layouts';
var Home = function Home(_ref) {
  var data = _ref.data, children = _ref.children;
  var projects = useMemo(function () {
    return data.projects.nodes.map(function (node) {
      return node.frontmatter;
    });
  }, []);
  var content = data.about.frontmatter;
  return React.createElement(Page, null, React.createElement(Hero, null), React.createElement(About, {
    content: content,
    children: children
  }), React.createElement(Projects, {
    content: projects
  }), React.createElement(OtherProjects, null));
};
var query = "1147812351";
Home
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Home, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
