import React, { useMemo, useState } from 'react';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

import { Button, Paragraph } from '@components';
import { useOtherProjectsQuery } from '@hooks/queries';
import { Section } from '@layouts';
import { device } from '@styles';

import Project from './Project';

const Container = styled.ul`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
    padding-top: 100px;

    @media ${device.tablet} {
        padding-top: 50px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 50px 0;
`;

const OtherProjects: React.FC = () => {
    const PROJECTS_LIMIT = 3;
    const [limit, setLimit] = useState(PROJECTS_LIMIT);
    const [clicked, setClicked] = useState(false);

    const data = useOtherProjectsQuery();

    const projects = data.slice(0, limit);
    const showButtons = useMemo(() => data.length > projects.length, []);

    const handleClick = () => () => {
        if (!clicked) setLimit(data.length);
        else setLimit(PROJECTS_LIMIT);
        setClicked(prevState => !prevState);
    };

    return (
        <Section gradient id="other">
            <Paragraph
                styles={{ size: 'large', color: 'orange' }}
                data={{
                    overline: 'WHAT I DID',
                    header: 'Other projects',
                }}
            />
            <Container>
                <TransitionGroup component={null}>
                    {projects &&
                        projects.map((project, index) => {
                            const {
                                name,
                                description,
                                tech,
                                linkIcons,
                                links,
                            } = project;
                            return (
                                <CSSTransition
                                    key={index}
                                    timeout={index * 100}
                                    exit={false}
                                >
                                    <Project
                                        key={index}
                                        links={
                                            links && linkIcons
                                                ? { linkIcons, links }
                                                : undefined
                                        }
                                        href={`/projects/${name}`}
                                        title={name}
                                        description={description}
                                        techStack={tech}
                                        style={{
                                            transitionDelay: `${index * 100}ms`,
                                        }}
                                    />
                                </CSSTransition>
                            );
                        })}
                </TransitionGroup>
            </Container>
            {showButtons && (
                <ButtonContainer>
                    <Button
                        onClick={handleClick()}
                        icon={clicked ? 'minus' : 'plus'}
                    >
                        Show {clicked ? 'less' : 'more'}
                    </Button>
                </ButtonContainer>
            )}
        </Section>
    );
};

export default OtherProjects;
