import React from 'react';

import styled, { css } from 'styled-components';

import { List, Paragraph } from '@components';
import { Section } from '@layouts';
import { device } from '@styles';
import type { ContentAbout } from 'src/types';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto auto;
    column-gap: 10px;
    padding-top: 80px;

    @media ${device.tablet} {
        padding-top: 10px;
        grid-template-columns: auto;
        grid-template-rows: auto;
    }
`;

const Container = styled.div<{ type: 'list' | 'paragraphs' }>`
    ${({ type }) =>
        type === 'list' &&
        css`
            display: flex;
            flex-direction: column;
            grid-row: span 2 / 1;
            grid-column: span 2 / -1;

            & > :nth-of-type(1) {
                padding-bottom: 10px;
            }

            @media ${device.tablet} {
                flex-direction: row;
                align-content: space-between;
                grid-row: 5;
                grid-column: span 2;
            }
        `}

    ${({ type }) =>
        type === 'paragraphs' &&
        css`
            grid-row: span 2;
            grid-column: span 7;
            color: #a0aaba;

            p {
                padding-bottom: 30px;
            }

            a {
                color: white !important;
                &:hover {
                    text-decoration: underline;
                }
            }

            @media ${device.tablet} {
                grid-column: span 2;
            }
        `}
`;

type AboutProps = {
    content: ContentAbout;
    children: React.ReactNode;
};

const About: React.FC<AboutProps> = ({ content, children }) => {
    const { skills, devskills } = content;

    return (
        <Section id="about">
            <Paragraph
                data={{ header: 'About me', overline: `WHAT'S THE STORY` }}
                styles={{ size: 'large', color: 'orange' }}
            />
            <Grid>
                <Container type="list">
                    <List single title="SKILLS" list={skills} />
                    <List single title="DEVELOPING SKILLS" list={devskills} />
                </Container>
                <Container type="paragraphs">{children}</Container>
            </Grid>
        </Section>
    );
};

export default About;
