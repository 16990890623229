import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';

import { Button, Paragraph, Icon } from '@components';
import { device } from '@styles';

const StyledHome = styled.section<{ active: boolean }>`
    height: 100vh;
    position: relative;
    margin: auto;

    background-size: 1.2rem 1.2rem;
    background-image: radial-gradient(
        hsla(0, 0%, 100%, 0.1) 1px,
        transparent 0
    );
    background-position: 0 -1.2rem;
`;

const Mask = styled.div`
    height: 100%;
    background: linear-gradient(transparent, var(--black)) 0 repeat;

    @media (max-width: 1330px) {
        padding: 0 50px;
    }
    @media ${device.tablet} {
        height: 100%;
        padding: 0 50px;
    }
    @media ${device.mobileL} {
        padding: 0 25px;
    }
`;

const Container = styled.div`
    margin: auto;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-width: 1280px;
    border-radius: 16px;

    @media ${device.tablet} {
        margin-top: 100px;
    }

    @media ${device.tablet} {
        flex-direction: column;
    }
`;

const Content = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    opacity: ${({ active }) => (active ? '1' : '0')};
    transition: all 0.7s ease-in-out 0.5s;

    p {
        margin: 16px 0px;
    }

    h4 {
        margin-bottom: 16px;
        color: var(--orange);
        font-size: 24px;

        @media (max-width: 1128px) {
            font-size: 14px;
        }
    }
`;

const AnimatedSvgWrapper = styled.div<{ active: boolean }>`
    svg {
        transition: opacity 0.7s ease-in-out 0.5s;

        .bg {
            transition: opacity 0.7s ease-in-out 3s;
        }

        .poland,
        .uk {
            transition: transform 0.7s ease-in-out 3.7s, color 0.7s ease 1.5s;
        }

        .poland-arrow {
            transition: opacity 0.5s ease-in-out 4.4s;
        }

        .poland-text {
            transition: opacity 0.5s ease-in-out 5s;
        }

        .uk-arrow {
            transition: opacity 0.5s ease-in-out 5.5s;
        }

        .uk-text {
            transition: opacity 0.5s ease-in-out 6.2s;
        }
    }

    ${({ active }) =>
        active &&
        css`
            svg {
                opacity: 1;
                .bg {
                    opacity: 0;
                }

                .poland,
                .poland-text,
                .poland-arrow {
                    color: var(--orange);
                    transform: translateX(10%) scale: 1.2;

                    @media ${device.tablet} {
                        scale: 1;
                    }
                }

                .poland-text,
                .poland-arrow,
                .uk-text,
                .uk-arrow {
                    opacity: 1;
                }

                .uk,
                .uk-text,
                .uk-arrow {
                    color: var(--orange);
                    transform: translateX(-10%);
                    scale: 1.2;

                    @media ${device.tablet} {
                        scale: 1;
                    }
                }
            }
        `}

    ${({ active }) =>
        !active &&
        css`
            svg {
                opacity: 0;
                .bg {
                    opacity: 1;
                }

                .poland,
                .uk {
                    color: var(--black);
                }

                .poland-text,
                .poland-arrow,
                .uk-text,
                .uk-arrow {
                    opacity: 0;
                }
            }
        `}

    svg {
        width: 100%;
        height: auto;
        object-fit: cover;

        path {
            pointer-events: all;
        }
    }
`;

const Hero: React.FC = () => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setAnimate(true);
    }, []);

    return (
        <StyledHome active={animate}>
            <Mask>
                <Container>
                    <Content active={animate}>
                        <Paragraph
                            data={{
                                header: `Hey there! I'm Maciek, a passionate software engineer.
                            `,
                            }}
                            styles={{
                                color: 'orange',
                                size: 'XL',
                            }}
                        />
                        <Button href="/resume.pdf" primary square arrow>
                            Resume
                        </Button>
                    </Content>
                    <AnimatedSvgWrapper active={animate}>
                        <Icon name="map" />
                    </AnimatedSvgWrapper>
                </Container>
            </Mask>
        </StyledHome>
    );
};

export default Hero;
