import React from 'react';

import styled from 'styled-components';

import { List, Paragraph, Links } from '@components';
import { device } from '@styles';

const StyledProject = styled.li`
    & > div {
        background-color: var(--bg-gray);
        padding: 30px;
        min-height: 375px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        transition: all 0.25s;

        @media ${device.tablet} {
            min-height: initial;
        }
    }

    &:hover,
    &:focus-within {
        & > div:first-of-type {
            transform: translateY(-5%);

            @media ${device.laptop} {
                transform: none;
            }
        }
    }

    header > * {
        margin-bottom: 20px;
    }

    header > div:first-of-type {
        text-align: right;
    }
`;

export interface ProjectProps
    extends React.HTMLAttributes<HTMLLIElement>,
        React.ClassAttributes<HTMLLIElement> {
    title: string;
    description: string;
    techStack: string[];
    href?: string;
    external?: string;
    links?: {
        linkIcons: string[];
        links: string[];
    };
}

const Project: React.FC<ProjectProps> = ({
    title,
    description,
    techStack,
    links,
    href,
    style,
}) => (
    <StyledProject style={style}>
        <div>
            <header>
                {links && <Links content={links} />}
                <Paragraph data={{ header: title, text: description, href }} />
            </header>
            <footer>
                <List list={techStack} />
            </footer>
        </div>
    </StyledProject>
);

export default Project;
