import React, { useRef } from 'react';

import styled from 'styled-components';

import { Project, Paragraph } from '@components';
import { Section } from '@layouts';
import type { SectionProjectsProps } from 'src/types';

const List = styled.ul`
    margin: 0 0;
`;

const Projects: React.FC<SectionProjectsProps> = ({ content }) => {
    const revealProjects = useRef([]);

    return (
        <Section id="projects" refs={revealProjects}>
            <Paragraph
                data={{
                    header: 'Featured projects',
                    overline: ' WHAT I DO',
                }}
                styles={{ size: 'large', color: 'orange' }}
            />
            <List>
                {content.map((project, i: number) => (
                    <Project
                        key={i}
                        index={i}
                        refs={revealProjects}
                        project={project}
                        odd={i % 2 === 1}
                    />
                ))}
            </List>
        </Section>
    );
};

export default Projects;
