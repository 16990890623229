/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "I started my journey with coding at ", React.createElement(_components.a, {
    href: "https://pja.edu.pl/en/"
  }, "Polish-Japanese Academy of Information Technology"), " in Warsaw where we used Java and some C++. As a student i enjoyed competetive gaming, so my first few projects involved games, or tools built around them."), "\n", React.createElement(_components.p, null, "Front-end felt as a natural and intuitive framework to learn programming conecepts and realised that visual side of creating things is what naturally resonates with me. From games\nto portfolio, and"), "\n", React.createElement(_components.h4, null, "Personal"), "\n", React.createElement(_components.p, null, "I'm a huge fan of exploring new areas,"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
